// Here you can add other styles

.img-avatar {
	margin-right: 25px !important;
}

.bg-primary {
	background-color: #f9f8e4 !important;
	border-color: #f9f8e4 !important;
}

.metrics-card {
	background-color: #2f353a !important;
}

.orders-filter-row-2 {
	margin-top: 10px;
}

.product-image {
	height: 60px;
	width: 60px;
}

@media only screen and (max-width: 960px) {
	.MuiTableCell-root {
		height: auto !important;
	}

	.product-image {
		height: 70px;
		width: 70px;
		border-radius: 5px;
	}
}